<template>
  <div class="input-number-container">
    <h6 v-if="title">{{ title }}</h6>
    <input
        type="number"
        :step="step"
        :placeholder="placeholder"
        :value="value"
        @input="update" />
    <p v-if="infoText" class="info">{{ infoText }}</p>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: "InputNumber",
  props: {
    title: String,
    option: String,
    index: null,
    oKey: null,
    placeholder: String,
    step: Number,
    infoText: String
  },
  computed: {
    ...mapGetters(['options']),
    value() {
      if(this.index !== undefined && this.oKey !== undefined)
        return this.options[this.option][this.index][this.oKey];
      else
        return this.options[this.option];
    }
  },
  methods: {
    ...mapActions(['updateOption']),
    update(event) {
      if(this.index !== undefined && this.oKey !== undefined)
        this.updateOption({ option: this.option, index: this.index, key: this.oKey, value: parseFloat(event.target.value), update: true });
      else
        this.updateOption({ option: this.option, value: parseFloat(event.target.value) });
    }
  }
}
</script>

<style lang="scss">
.input-number-container {
  width: 100%;

  h6 {
    margin-bottom: 4px;
  }

  .info {
    margin-top: 8px;

    @media (max-width: $width-tablet) {
      font-size: 0.75rem;
      line-height: initial;
    }
  }
}
</style>