<template>
  <tr>
    <td class="flex-row-center">
      <button @click="updateOption({ option: option, index: index, delete: true })">
        <img :src="require('@/assets/cancel.svg')" />
      </button>
      <select @change="updateOption({ option: option, value: $event.target.value, index: index, key: 'type', update: true })">
        <option value="constantLoad" :selected="options[option][index]['type'] === 'constantLoad'">Ständige Einzel-, Blocklast</option>
        <option value="changingLoad" :selected="options[option][index]['type'] === 'changingLoad'">Veränderl. Einzel-, Blocklast</option>
      </select>
    </td>
    <td class="flex-vertical-center pr-16">
      <InputNumber
          :option="option"
          :index="index"
          oKey="spacingA"
          :step="1" />
    </td>
    <td class="flex-vertical-center pr-16">
      <InputNumber
          :option="option"
          :index="index"
          oKey="spacingB"
          :step="1" />
    </td>
    <td class="flex-vertical-center">
      <InputNumber
          :option="option"
          :index="index"
          oKey="load"
          :step="1" />
    </td>
  </tr>
</template>

<script>
import InputNumber from "@/components/InputNumber";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "InputSingleLoadItem",
  components: {InputNumber},
  props: {
    index: Number,
    option: String
  },
  computed: {
    ...mapGetters(['options'])
  },
  methods: {
    ...mapActions(['updateOption'])
  }
};
</script>

<style lang="scss">
.pr-16 {
  padding-right: 16px !important;
}
</style>