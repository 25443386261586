<template>
  <div class="payload">
    <div class="grid two-columns">
      <div class="grid-item">
        <InputNumber
            title="Nutzlast (kN/m²)"
            option="payload"
            :step="1"
            info-text="Max. 12,5 kN/m²" />
      </div>

      <div class="grid-item">
        <InputNumber
            title="Ausbaulast (kN/m²)"
            option="additionalLoad"
            :step="1"
            info-text="Max. 5 kN/m²" />
        <img class="payload-image" :src="require('@/assets/payload.svg')" />
      </div>
    </div>

    <InputSingleLoad
        class="spacing"
        title="Einzellasten hinzufügen"
        option="singleLoads"
        :optional="true" />
  </div>
</template>

<script>
import InputNumber from "@/components/InputNumber";
import InputSingleLoad from "@/components/InputSingleLoad";
export default {
  name: "Payload",
  components: {InputSingleLoad, InputNumber}
};
</script>

<style lang="scss">
.payload {
  .grid {
    grid-column-gap: 32px;

    @media (max-width: $width-tablet) {
      grid-template-columns: 1fr;
      grid-row-gap: 24px;
    }
  }

  .payload-image {
    width: 100%;
    height: auto;
    margin-top: 24px;
  }

  .spacing {
    margin-top: 24px;
  }
}
</style>