<template>
  <table class="input-single-load">
    <thead>
      <tr>
        <td><span v-if="optional" class="optional">Optional </span>{{ title }}</td>
        <td>Abstand a (m)</td>
        <td>Länge b (m)</td>
        <td>Last q (kN/m²)</td>
      </tr>
    </thead>
    <tbody>
      <InputSingleLoadItem
          v-for="(item, index) in options[option]"
          :key="index"
          :index="index"
          :option="option" />
    </tbody>
    <tfoot v-if="options.singleLoads.length < 4">
      <tr>
        <td colspan="4">
          <button @click="updateOption({ option: option, value: { type: 'constantLoad', spacingA: 0, spacingB: 0, load: 0 }, insert: true })">
            <img :src="require('@/assets/add.svg')" />
            Einzellast hinzufügen
          </button>
        </td>
      </tr>
    </tfoot>
  </table>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import InputSingleLoadItem from "@/components/InputSingleLoadItem";
export default {
  name: "InputSingleLoad",
  components: {InputSingleLoadItem},
  props: {
    title: String,
    option: String,
    optional: Boolean
  },
  computed: {
    ...mapGetters(['options'])
  },
  methods: {
    ...mapActions(['updateOption'])
  }
};
</script>

<style lang="scss">
.input-single-load {
  display: grid;
  grid-template-columns: 4fr 1fr 1fr 1fr;
  width: 100%;

  font-size: 0.762rem;

  @media (max-width: $width-tablet) {
    font-size: 1rem;
  }

  @media (max-width: $width-tablet) {
    grid-template-columns: 1fr;
  }

  thead, tbody, tr {
    display: contents;
  }

  tr {
    height: auto;

    td {
      width: 100%;
      height: 100%;
      padding: 8px 0 8px 0;
      border-bottom: 1px solid #69777F;
    }
  }

  button {
    display: flex;
    align-items: center;
    width: auto;
    height: 100%;

    background-color: transparent;
    border: none;
    font-family: futura-pt-condensed, sans-serif;
    font-weight: bold;
    font-size: 0.762rem;
    cursor: pointer;

    img {
      margin-right: 8px;
    }
  }

  thead {
    font-family: futura-pt-condensed, sans-serif;
    color: #293136;
    font-weight: bold;

    .optional {
      color: #69777F;
    }
  }

  tbody {
    font-family: futura-pt-book, sans-serif;

    button {
      color: #293136;
    }

    input {
      height: 100%;
      padding: 0 0 4px 0;

      border: none;
      border-bottom: 1px solid #CAD3D9;
    }

    @media (max-width: $width-tablet) {
      input {
        width: 100%;

        @media (max-width: $width-tablet) {
          font-size: 1rem;
        }
      }

      select {
        width: 100%;

        @media (max-width: $width-tablet) {
          font-size: 1rem;
        }
      }
    }
  }

  tfoot {
    td {
      padding: 8px 0 0 0;
      border-bottom: 0;

      button {
        color: #E3000B;

        @media (max-width: $width-tablet) {
          font-size: 1rem;
        }
      }
    }
  }
}
</style>